@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1rem;
  --typing-font:3rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
*
{
  margin:0;
  padding: 0;
}
body{
  font-family: 'Raleway';
  font-size:12px!important;
}
.p-datepicker{
  transform: scale(0.75)!important;
}
.p-accordion .p-accordion-content {
  background: #fcfffc!important;
}

.manager-tab .row {
  width: 100%;
}

.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
nav
{
  padding:0px ;
 /* flex:var(--sidebar-flex); */
  background: #003366;

}
.form-control-sm{
  font-size:10px!important;
}
.form-field-bold{
  color:black!important;
}
.dark-blue-bg
{
  background: #003366;
  color:#fff;

}
.light-blue-bg,#search-filters .p-button,.container-filters{
  background:#ccccff;
  color:#003366;
}
.close {
  font-size: 12px!important;
  color: #003366!important;
  margin-right: -9px;
  margin-top: -15px;
  opacity: 1!important;
}
.p-tabview-title,.p-tabmenuitem span{
  color:#003366;
}
.box-title{
  margin-top: -15px;
  font-weight: bold;
  font-size: 14px;
}
/** Notes Admin */
.admin-notes-div {
  width: 100%;
  border: 1px solid red;
  background: white;
  color: black;
  z-index: 999999999999;
  position: sticky;
  top: 0;
}
#search-filters{
  margin-top:6px;
}
.container-filters {
  padding: 10px;
  width: 98%;
  margin-left: 12px;
  color:#9999cc;
}
#search-btn {
  padding: 10px;
  width: 88px;
  font-weight: bold;
  background: #ccccff!important;
  border: none;
  margin-right: 43px;
  border-radius: 0;
}
.p-component,.p-datepicker table{
  font-size:12px!important;
}
.p-datatable-tbody a{
  color:#003366!important;
  font-weight: bold;
}
.p-datatable-tbody span{
  font-size:10px;
}
.p-tabview-selected .p-tabview-nav-link{
  background: #9a9b9c !important;
  color:#003366!important;
  font-weight: bold;
}
.form-group{margin-bottom: 5px!important;}
.checkbox_label {
  margin-top: 9px;
  margin-left: 10px;
  margin-bottom: -10px;
}
.component-box{
  padding: 9px 0px 52px;
  width: 100%;
  mask-image:
      linear-gradient(135deg,transparent 0rem, gold 0 ),
      linear-gradient(-135deg, transparent 1.15rem, gold 0),
      linear-gradient(45deg,transparent 0rem,gold 0),
      linear-gradient(-45deg, transparent 0rem, gold 0);
  mask-position: 0 0, 65% 0, 0 100%, 100% 100%;
  mask-repeat: no-repeat;
  mask-size: 50% 90%;
}
.component-box.light-blue-bg > .row {
  padding: 11px 28px 0px 18px;
}
.component-box.light-blue-bg .col-md-8 .row {
  padding: 6px 10px 0px 0;
}
.component-box.light-blue-bg{
  width:100%;
  mask-position: -58% 100%, -59% 0%, 0% 99%, 100% 100%;
  mask-size:50% 91%;
  /*padding:9px 22px 37px 0px; */
  padding: 14px ​22px 25px 0p;
}
.component-box.dark-blue-bg .row {
  padding: 10px 8px 0px 18px;
}
#dateSearchComponent {
  margin-top: 10px;
}
#dateSearchComponent .row {
  padding:1px 0px!important;
  /*margin-right: 27px; */
}
.container-detailsearch{
  margin-left: -26px;
}
#dateSearchComponent .p-calendar .p-inputtext {
  font-size: 10px;
  padding: 0px 1px 2px 3px;
  width: 61px;
}
.order_details_calendar {
  left: 1000px!important;
}
#lang-selector span a{
  color:#003366!important;
}
#lang-selector span a.current{
  font-weight: bold;
}
#dateSearchComponent .p-button.p-button-icon-only {
  width: auto!important;
  padding: 0px!important;
}
#searchFields .row{
  padding:4px 10px;
}
.box-report-info {
  padding: 10px;
  color: #fff;
  margin-left: -32px;
}
.bg-blue{
  background:#006699;
}
.bg-green{
  background:#006699;
}
.bg-yellow{
  background: #006699;
}
.ccp-add-btn{
  width: 1.3rem !important;
  top: -6px;
}
.float-right.ccp-add-btn {
  margin-top: 7px!important;
}
/** Datables styles **/
#dataTable{width: 100%;}
.p-datatable .p-datatable-thead > tr > th,#statTable th{
  background-color: #003366!important;
  color:#ffffff!important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd{
  background:#cccccc!important;
}
.p-datatable .p-datatable-tbody > tr > td{
  padding: 2px!important;
}
.p-column-title{
  text-transform: uppercase;
}
.p-dropdown,.p-dropdown-label,.p-multiselect-label{
  font-size:10px!important;
  height: 30px;
  width: 140px;
  color: #6c757d;
}
.p-datatable .p-datatable-thead > tr > th,#statTable th{
  padding: 10px !important;
  font-size: 10px!important;
}
.p-datatable-tbody .p-dropdown-label{
  width: 2%;
}
.p-datatable-tbody .p-dropdown{
  width:75px;
}
.p-datatable-tbody .p-dropdown .p-dropdown-trigger{
  width: 1.357rem;
}
.hidden{display:none!important};
.visible{display: block!important}
 .alert a{color: #006699!important; text-decoration: underline;}
/** End Datatables style **/

/** Multi select CSS */
.multiselect-demo .p-multiselect {
  min-width: 15rem;
}
#container-quicksearch .p-multiselect-label-container{
  width:100px;
}

/*** Inquiry Details TaB Content **/
.p-tabview .p-tabview-nav{
  position: sticky;
   top: 0;
   z-index: 100;
}
#inquiry_contat_info .phone [class*="col-"] {
  padding: 1px!important;
}
#inquiry_details_info .action-buttons .p-button {
  border:0px!important;
  position: sticky;
  top: 35px;
  z-index: 100;
  background:#003366!important;
}
#inquiry_details_info .p-tabview .p-tabview-nav{
  border:0px!important;
  position: sticky;
  top: 0;
  z-index: 100;
}

#inquiry_details_info .p-tabview .p-tabview-nav li .p-tabview-nav-link{
  border:0px!important;
}
#inquiry_details_info .p-tabview .p-tabview-nav li {
  margin-left: 10px;
  width:18%;
}
#inquiry_details_info .p-unselectable-text .p-tabview-nav-link{
  background:#cccccc!important;
}
#inquiry_details_info .p-tabview-selected .p-tabview-nav-link{
  background:#003366!important;
}
#inquiry_details_info .p-tabview-title{
  color: #fff!important;
}
.p-chips .p-chips-multiple-container {
  padding: 5px 30px 50px 10px!important;
  width: 100%;
  height: auto!important;
}
/*** End Inquiry Details TaB Content **/
/** Inquiry Accordion COntents **/
.p-accordion-header .p-accordion-header-link{
  background:#003366!important;
}

.nested-accordion .p-accordion-header .p-accordion-header-link{
  background:#fff!important;
  border: 1px solid #003366!important;
  color:#003366!important;
}
.nested-accordion .p-accordion .p-accordion-tab{
  margin-bottom: 10px!important;
}
/** END Accordion contents **/
/** FORM STYLES **/
.form-title{
  font-weight: bold;
  color:#003366;
}
.form-field-bold{
  font-weight: bold;
  color:darkgrey;
}
.float-right{
  float: right;
}
.lg-text-area{
  height:300px!important;
}
.text-blue{
  color:#003366!important;
}
.form-field-italic{
  font-style: italic;
}
.p-tooltip .p-tooltip-text{
  background: #cccccc!important;
  color:#003366!important;
  border:1px solid #003366;
  padding: 10px;
}
.form-text-comments{
  font-size: 12px!important;
  font-style: italic;
}
/*.p-button{
  background:#003366!important;
}*/
.p-button-square{
  background: #fff !important;
  color: #003366!important;

}
.p-button-square .p-button-icon {
  font-size: 12px;
}
.wborder{
  border:1px solid red;
}
.form-hr{
  border-bottom:1px solid #003366!important;
  margin:10px 0;
}
.form-hr-sm{
  border-bottom:1px solid #cccccc;
  margin:10px 0;
}
.pi{
  font: size 12px !important;
}
.p-inputtext{
  height: 30px!important;
}
#container-detailedSearch .p-multiselect-label-container{
  width:100px;
}

.p-button.p-button-icon-only{
  padding:0.1rem 0!important;
}
/** END FORM STYLES **/
/** Calendar icon **/
.p-calendar.p-calendar-w-btn .p-inputtext {
  order: 2;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-calendar.p-calendar-w-btn .p-datepicker-trigger {
  order: 1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/** End calendar icon **/
.nav_icon{
  background-image: url("img/inactive_icon.png");
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
.active .nav_icon{
  background-image: url("img/active_icon.png");
}

.home_icon{
  background-image: url("img/home.png");
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
.condiv
{
  /*padding: 100px ;*/
  background-color: #f4f5f9;
  flex:var(--condiv-flex);

}
.main_header {
  background: #cccccc;
  padding: 18px 0px 1px;
  color:#003366;
  font-weight: bold;
}
.spacer{margin-bottom:5px;}
.lg-spacer{margin-top:10px;}
.logo{
  float: right;
  height:80%;
}
.home
{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
.profilepic
{
  border-radius: 50%;
  width: 200px;
  margin: 30px;
}
.typingeffect
{
  font-size: var(--typing-font);
  margin-bottom: 30px;
}

nav ul{
  font-size:var(--fontsize-nav) ;
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: white !important;
}
nav ul li {
  margin: 9px 7px 17px;
  border-bottom: 2px solid;
  padding: 9px 2px;
}
a
{
  text-decoration: none;
  color: white!important;
}
a:hover{
	text-decoration:none!important;
}
.subtopic
{
  margin:10px 10px 30px 10px !important;
}
p{
  font-size: var(--fontsize-p);
}
h1,h2,h3,h4,p{
  margin: 10px;
}
.social
{
  position: absolute;
  margin-top: 20px;
  display: flex;
  bottom: 60px;
}
.social i
{
  padding-right: 30px;
  font-size: 25px;
  color: #9a9b9c;
}
.social i:hover
{
  color: #ffffff;
}
.widecard
{
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
}
.widecard:hover
{
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.active
{
  /*font-weight: bolder; */
}
.skills ul li
{
  list-style-type:none;
  margin:30px;
}

#reportPreview .row div{
    border:1px solid #000;
}
#reportPreview .row div.noBorder{
  border:none!important;
}
#reportPreview .row .strong{
  font-weight: bold;
  font-size:15px;
}
.coface{
  border:1px solid red;
}
#statTable .even{
  background:#ddd;
}
#statTable .even.bold{
  font-weight: bold;
  border-top:1px solid #000;
}
.financial-report-container .row{
  margin-top:3px;
}
.financial-report-container input{
  height:22px;
}
.adminNotes{
  word-break: break-word;
  margin-left: -10px;
  margin-top: 10px;
  max-height: 500px;
  overflow-y: auto;
  white-space: pre-wrap;
}
.loadingDiv{
  background: #cccc;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
   
}
.textLoading {
  margin: 0 30%;
  top: 100px;
  font-size: 15px;
  padding-top: 40px;
  width:300px;
}
.required{
  color:red;
}
.searchHeaders .col-sm-1{
  padding-right:2px;
}
.p-dialog-title{
  padding-left: 10px;
}
.p-accordion-content .row{
  margin-bottom: 2px;
}
.row.searchHeaders div {
  margin-bottom: 6px;
} 
.row.searchHeaders .multiSelect div{
  margin-bottom: 0px;
}
@media all and (max-width: 450px) {
  :root {
    --fontsize-nav:1rem;
    --sidebar-flex:0;
    --typing-font:2rem;
  }
  .App
  {
    flex-direction: column;
  }
  .sidebar
  {
    padding: 30px;
    background: rgb(238,103,143);
    background: linear-gradient(90deg, rgba(238,103,143,1) 50%, rgba(132,124,252,1) 80%);
  }
  .condiv
  {
    padding: 50px ;
  }
  nav ul
  {
    display: flex;
  }
  nav ul li
  {
    margin: 10px;
  }
  .social
  {
    left:0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    bottom: 30px;
  }
  .social i
  {
    padding-right: 0px;
  }
}
